import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//群发列表
export function GetGroupSendList(data) {
    return $axios.post(`${baseURL}/GroupSend/GetList`, data);
}

//新建群发
export function CreateGroupSend(data) {
    return $axios.post(`${baseURL}/GroupSend/Create`, data);
}

//群发详情
export function GetDetailGroupSend(data) {
    return $axios.post(`${baseURL}/GroupSend/GetDetail`, data);
}

//取消未开始群发
export function CancelGroupSend(data) {
    return $axios.post(`${baseURL}/GroupSend/Cancel`, data);
}

//更新群发
export function UpdateGroupSend(data) {
    return $axios.post(`${baseURL}/GroupSend/Update`, data);
}

//获得单个群发中的失败任务
export function GetFailureList(data) {
    return $axios.post(`${baseURL}/GroupSend/GetFailureList`, data);
}


//获取微信关键字
export function GetForbiddenWords(data) {
    return $axios.post(`${baseURL}/GroupSend/GetForbiddenWords`, data);
}

//获取所有群信息(群选择)
export function GetAllGroup(data) {
    return $axios.post(`${baseURL}/Group/GetAllGroup`, data);
}

//重发失败任务
export function GroupSendRetry(data) {
    return $axios.post(`${baseURL}/GroupSend/Retry`, data);
}

//群发测试
export function GroupCreateTest(data) {
    return $axios.post(`/GroupSend/CreateTest`, data);
}
/---------------------------------------------------私聊----------------------------------------/
//私聊群发
export function GetPrivateSendList(data) {
    return $axios.post(`${baseURL}/PrivateSend/getList`, data);
}

//取消私聊群发
export function CancelPrivateSend(data) {
    return $axios.post(`${baseURL}/PrivateSend/Cancel`, data);
}

//获取单个私聊失败任务
export function GetPrivateFailureList(data) {
    return $axios.post(`${baseURL}/PrivateSend/GetFailureList`, data);
}

//私聊失败重发
export function PrivateSendRetry(data) {
    return $axios.post(`${baseURL}/PrivateSend/Retry`, data);
}

//新建私聊
export function CreatePrivateSend(data) {
    return $axios.post(`${baseURL}/PrivateSend/Create`, data);
}

//更新私聊
export function UpdatePrivateSend(data) {
    return $axios.post(`${baseURL}/PrivateSend/Update`, data);
}

//返回私聊群发客户名单
export function GetdestContact(data) {
    return $axios.post(`${baseURL}/PrivateSend/GetdestContact`, data);
}

//返回预估发送数量
export function GetSimulateCount(data) {
    return $axios.post(`${baseURL}/PrivateSend/SimulateCount`, data);
}

//私聊详情
export function GetPrivateSendDetail(data) {
    return $axios.post(`${baseURL}/PrivateSend/GetDetail`, data);
}


//当前群公告
export function GetGroupAnnounceList(data) {
    return $axios.post(`${baseURL}/GroupAnnounce/GetList`, data);
}

//历史群公告
export function GetGroupAnnounceLog(data) {
    return $axios.post(`${baseURL}/GroupAnnounce/GetLog`, data);
}

//发布群公告
export function AddGroupAnnounce(data) {
    return $axios.post(`${baseURL}/GroupAnnounce/Add`, data);

}

//群明细列表（已开通）
export function GetGroupList(data) {
    return $axios.post(`${baseURL}/Group/GetList`, data);
}

//注销群
export function SetGroupUnOpen(data) {
    return $axios.post(`${baseURL}/Group/SetGroupUnOpen`, data);

}

//转移助手
export function TransferGroup(data) {
    return $axios.post(`${baseURL}/Group/Transfer`, data);

}


//获得待开通群列表
export function GetUnopenList(data) {
    return $axios.post(`${baseURL}/Group/GetUnopenList`, data);
}
//从待开通群列表中删除群
export function DeleteUnopenGroup(data) {
    return $axios.post(`${baseURL}/Group/DeleteUnopen`, data);

}
//开通群
export function SetOpenGroups(data) {
    return $axios.post(`${baseURL}/Group/SetOpen`, data);

}

//获取群聊邀请配置
export function GetAutoAuthHour(data) {
    return $axios.post(`${baseURL}/Config/GetAutoAuthHour`, data);

}

//设置群聊邀请配置
export function SetAutoAuthHour(data) {
    return $axios.post(`${baseURL}/Config/SetAutoAuthHour`, data);

}

//设置启用禁用群聊邀请配置
export function SwitchAutoAuth(data) {
    return $axios.post(`${baseURL}/Config/SwitchAutoAuth`, data);

}
//获得成员列表(简易版)
export function GetGroupMemberSimpleList(data) {
    return $axios.post(`${baseURL}/GroupMember/SimpleList`, data);

}

//获得成员列表
export function GetGroupMemberList(data) {
    return $axios.post(`${baseURL}/GroupMember/GetList`, data);

}
//获取当前品牌所有的群主列表
export function GetGroupOwnerName(data) {
    return $axios.post(`${baseURL}/Group/GetGroupOwnerName`, data);

}
//标签组列表
export function CorpTagGetGroupStats(data) {
    return $axios.post(`/CorpTag/GetGroupStats`, data);
}
//标签组内情况列表
export function CorpTagGetStats(data) {
    return $axios.post(`/CorpTag/GetStats`, data);
}
//同步标签组和标签名称
export function CorpTagSyncTag(data) {
    return $axios.post(`/CorpTag/SyncTag`, data);
}
//同步标签组的客户
export function CorpTagSyncCustomerByTagGroup(data) {
    return $axios.post(`/CorpTag/SyncCustomerByTagGroup`, data);
}
//同步单个标签的客户
export function CorpTagSyncCustomerByTag(data) {
    return $axios.post(`/CorpTag/SyncCustomerByTag`, data);
}
//取消同步任务
export function CorpTagCancelJob(data) {
    return $axios.post(`/CorpTag/CancelJob`, data);
}
//同步标签名称的任务结果
export function CorpTagGetSyncTagResult(data) {
    return $axios.post(`/CorpTag/GetSyncTagResult`, data);
}

//群公告发送结果
export function GroupAnnounceGetSendResult(data) {
    return $axios.post(`/GroupAnnounce/GetSendResult`, data);
}
//取消尚未发布的群公告
export function GroupAnnounceCancel(data) {
    return $axios.post(`/GroupAnnounce/Cancel`, data);
}
//获得群公告详情
export function GroupAnnounceGetDetail(data) {
    return $axios.post(`/GroupAnnounce/GetDetail`, data);
}
//加入白名单
export function GroupMemberSetRoleWhite(data) {
    return $axios.post(`/GroupMember/SetRoleWhite`, data);
}
//获取白名单用户
export function GroupMemberGetWhiteList(data) {
    return $axios.post(`/GroupMember/GetWhiteList`, data);
}
//移除白名单
export function GroupMemberRemoveRoleWhite(data) {
    return $axios.post(`/GroupMember/RemoveRoleWhite`, data);
}
//获取自动回复列表
export function AutoReplyGetList(data) {
    return $axios.post(`/AutoReply/GetList`, data);
}
//获取自动回复状态
export function ConfigGetAutoReply(data) {
    return $axios.post(`/Config/GetAutoReply`, data);
}
//开启或关闭自动回复
export function ConfigSetAutoReply(data) {
    return $axios.post(`/Config/SetAutoReply`, data);
}
//新建或编辑自动回复任务
export function AutoReplyCreate(data) {
    return $axios.post(`/AutoReply/Create`, data);
}
//删除自动回复任务
export function AutoReplyDelete(data) {
    return $axios.post(`/AutoReply/Delete`, data);
}
//获取自动回复任务详情
export function AutoReplyGetDetail(data) {
    return $axios.post(`/AutoReply/GetDetail`, data);
}
//修改群名称
export function GroupModifyChatName(data) {
    return $axios.post(`/Group/ModifyChatName`, data);
}

//创建群
export function GroupCreateGroupChat(data) {
    return $axios.post(`/Group/CreateGroupChat`, data);
}
//踢人
export function GroupRemoveMember(data) {
    return $axios.post(`/Group/RemoveMember`, data);
}
//获取群内企业好友
export function GroupGetCompanyContact(data) {
    return $axios.post(`/Group/GetCompanyContact`, data);
}
//设置群管理员
export function GroupSetGroupAdmin(data) {
    return $axios.post(`/Group/SetGroupAdmin`, data);
}
//邀请一个或多个企业成员进群
export function GroupInviteCompanyContact2Group(data) {
    return $axios.post(`/Group/InviteCompanyContact2Group`, data);
}

//获取入群欢迎语列表
export function WelcomeMsgGetList(data) {
    return $axios.post(`/WelcomeMsg/GetList`, data);
}
//新建或编辑入群欢迎语任务
export function WelcomeMsgCreate(data) {
    return $axios.post(`/WelcomeMsg/Create`, data);
}
//获取入群欢迎语任务详情
export function WelcomeMsgGetDetail(data) {
    return $axios.post(`/WelcomeMsg/GetDetail`, data);
}
//删除入群欢迎语任务
export function WelcomeMsgDelete(data) {
    return $axios.post(`/WelcomeMsg/Delete`, data);
}
//开启或关闭入群欢迎语
export function SetWelcomeMsg(data) {
    return $axios.post(`/Config/SetWelcomeMsg`, data);
}
//批量设置多个群的入群欢迎语
export function GroupBatchSetWelcomeMsg(data) {
    return $axios.post(`/Group/BatchSetWelcomeMsg`, data);
}
//获得指定群详情
export function GroupGetInfo(data) {
    return $axios.post(`/Group/GetInfo`, data);
}
//入群欢迎语列表(选择用)
export function WelcomeMsgSimpleList(data) {
    return $axios.post(`/WelcomeMsg/SimpleList`, data);
}
//设置单个群的入群欢迎语
export function GroupSetWelcomeMsg(data) {
    return $axios.post(`/Group/SetWelcomeMsg`, data);
}
//踢人
export function RemoveAllGroup(data) {
    return $axios.post(`/GroupMember/RemoveAllGroup`, data);
}

//防骚扰规则
export function SyncAntiHarassmentRules(data) {
    return $axios.post(`/Group/SyncAntiHarassmentRules`, data);
}
//防骚扰列表
export function GetAntiHarassmentRuleList(data) {
    return $axios.post(`/Group/GetAntiHarassmentRuleList`, data);
}
//设置防骚扰群
export function SetAntiHarassmentRule(data) {
    return $axios.post(`/Group/SetAntiHarassmentRule`, data);
}
//批量设置防骚扰规则
export function BatchSetAntiHarassmentRule(data) {
    return $axios.post(`/Group/BatchSetAntiHarassmentRule`, data);
}

//重发失败任务
export function GroupAnnounceRetry(data) {
    return $axios.post(`/GroupAnnounce/Retry`, data);
}
//批量修改群聊保护
export function BatchSetAutoAuth(data) {
    return $axios.post(`/Group/BatchSetAutoAuth`, data);
}
//批量修改群名保护
export function BatchSetRestrictName(data) {
    return $axios.post(`/Group/BatchSetRestrictName`, data);
}

//开关群聊邀请
export function SetAutoAuth(data) {
    return $axios.post(`/Group/SetAutoAuth`, data);
}
//开关群名保护
export function SetRestrictName(data) {
    return $axios.post(`/Group/SetRestrictName`, data);
}

//批量下载群二维码
export function BatchDownloadGroupQRcode(data) {
    return $axios.post(`/Group/BatchDownloadGroupQRcode`, data);
}

//刷新二维码
export function GetGroupQRcode(data) {
    return $axios.post(`/Group/GetGroupQRcode`, data);
}

//获取入群欢迎页发送时间段
export function ConfigGetWelcomeMsgHour(data) {
    return $axios.post(`/Config/GetWelcomeMsgHour`, data);
}

//设置入群欢迎页发送时间段
export function ConfigSetWelcomeMsgHour(data) {
    return $axios.post(`/Config/SetWelcomeMsgHour`, data);
}

//终止进行中的群发
export function GroupSendStop(data) {
    return $axios.post(`/GroupSend/Stop`, data);
}
//终止进行中的发送任务
export function PrivateSendStop(data) {
    return $axios.post(`/PrivateSend/Stop`, data);
}

//终止发送中的群公告
export function GroupAnnounceStop(data) {
    return $axios.post(`/GroupAnnounce/Stop`, data);
}

//导出群列表(修改群名称)
export function GroupModifyExportGroupList(data) {
    return $axios.post(`/GroupModify/ExportGroupList`, data);
}

//创建群名称修改任务
export function GroupModifyCreate(data) {
    return $axios.post(`${baseURL}/GroupModify/Create?type=${data.Type}&name=${data.Name}`,data.formData);
}

//群名称修改明细
export function GroupModifyTaskList(data) {
    return $axios.post(`/GroupModify/TaskList`, data);
}

//导出修改明细
export function GroupModifyExportTaskInfo(data) {
    return $axios.post(`/GroupModify/ExportTaskInfo`, data);
}

//取消未开始的任务
export function GroupModifyCancel(data) {
    return $axios.post(`/GroupModify/Cancel`, data);
}

//终止进行中的任务
export function GroupModifyStop(data) {
    return $axios.post(`/GroupModify/Stop`, data);
}

//私聊测试
export function PrivateCreateTest(data) {
    return $axios.post(`/PrivateSend/CreateTest`, data);
}

//获取邀请逻辑
export function ConfigGetJoinGroupStatus(data) {
    return $axios.post(`/Config/GetJoinGroupStatus`, data);
}

//设置邀请逻辑
export function ConfigSetJoinGroupStatus(data) {
    return $axios.post(`/Config/SetJoinGroupStatus`, data);
}

//获取1v1自动回复状态
export function ConfigGetPrivateReply(data) {
    return $axios.post(`/Config/GetPrivateReply`, data);
}

//开启或关闭1v1自动回复
export function ConfigSetPrivateReply(data) {
    return $axios.post(`/Config/SetPrivateReply`, data);
}

//获取回复规则列表
export function PrivateReplyRuleList(data) {
    return $axios.post(`/PrivateReply/RuleList`, data);
}

//设置回复规则
export function PrivateReplySetRule(data) {
    return $axios.post(`/PrivateReply/SetRule`, data);
}

//获取助手上班时间
export function PrivateReplyWorkTime(data) {
    return $axios.post(`/PrivateReply/WorkTime`, data);
}

//设置助手上班时间
export function PrivateReplySetWorkTime(data) {
    return $axios.post(`/PrivateReply/SetWorkTime`, data);
}

//获取回复列表
export function PrivateReplyTaskList(data) {
    return $axios.post(`/PrivateReply/TaskList`, data);
}

//新建或编辑自动回复任务
export function PrivateReplyCreate(data) {
    return $axios.post(`/PrivateReply/Create`, data);
}

//删除自动回复任务
export function PrivateReplyDelete(data) {
    return $axios.post(`/PrivateReply/Delete`, data);
}

//获取回复任务详情
export function PrivateReplyDetail(data) {
    return $axios.post(`/PrivateReply/Detail`, data);
}

//测试根据群ID返回对应群
export function GetListBySerialNo(data) {
    return $axios.post(`/Group/GetListBySerialNo`, data);
}

//设置默认防骚扰规则
export function GroupSetDefaultAntiHarassmentRule(data) {
    return $axios.post(`/Group/SetDefaultAntiHarassmentRule`, data);
}