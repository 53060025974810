<template>
  <div class="container">
    <el-dialog title="关联群" width="1000px" v-model="dialogVisible">
      <div class="wrap">
        <div class="table-search" >
          <el-space>
            <div>
              <span>群名称：</span>
              <el-input v-model="searchOption.key" placeholder="请输入" style="width:180px"></el-input>
            </div>
            <div>
              <span>状态：</span>
              <el-select v-model="searchOption.status" placeholder="全部" style="width:180px">
                <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div>
              <span>发布时间:</span>
              <el-date-picker v-model="searchOption.dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:240px">
              </el-date-picker>
            </div>
          </el-space>
          <div>
            <el-button type="primary" @click="searchAction">搜索</el-button>
            <el-button @click="resetAction">重置</el-button>
          </div>
        </div>
        <div class="group-info">
          <el-space>
            <div>
              <span>任务名称:{{missionName}}</span>
              <span></span>
            </div>
          </el-space>
          <div>
            <el-button style="width:130px" type="primary" @click="lotsRetryAction" v-if="sourceData.data.TotalRetry==1">批量重发</el-button>
            <el-button style="width:130px" type="info" v-if="sourceData.data.TotalRetry==0">发送中</el-button>
          </div>
        </div>
        <el-table :data="tableData" style="width: 100%" border max-height="500">
          <el-table-column prop="GroupName" label="群名称" ></el-table-column>
          <el-table-column prop="SendDateTime" label="发送时间"></el-table-column>
          <el-table-column prop="StatusStr" label="发送结果">
            <template #default="scope">
              <div v-if="scope.row.StatusStr=='失败'">{{scope.row.StatusStr}}({{scope.row.CallBackvcResult}})</div>
              <div v-else>{{scope.row.StatusStr}}</div>
            </template>
          </el-table-column>
          <el-table-column  label="操作" width="100">
            <template #default="scope">
              <el-button type="primary" @click="resendAction(scope.row)" v-if="scope.row.RowRetry==1">重发</el-button>
              <el-button type="info" v-if="scope.row.RowRetry==0">发送中</el-button>
            </template>
          </el-table-column>
        </el-table>
         <TableFoot
        :pagination="pageObj" 
        @changePage="changePage" 
        @changeSize="changeSize" />
      </div>

      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button> -->
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import TableFoot from "@/components/TableFoot";
import {GroupAnnounceGetSendResult,GroupAnnounceRetry} from '@/helper/group'
import tools from "@/assets/js/tools";
import { ElMessage } from 'element-plus';

export default {
  components: {
    TableFoot,
  },

  setup(props, context) {
    const dialogVisible = ref(false);
    const tableData = ref([]);
    const gid=ref("")
    const missionName=ref("")
    const sourceData = reactive({
      data:{}
    })
    const searchOption=reactive({
      key:"",
      status:-1,
      dateValue:""
    })

    const statusList = [
      { label: "全部", value: -1 },
      { label: "已发布", value: 2 },
      { label: "已取消", value: 3 },
    ];


    function initCop(item) {
      dialogVisible.value = true;
      gid.value=item.GAID
      missionName.value = item.Name
      searchAction();
    }

    //搜索
    function searchAction() {
      let start = "";
      let end="";
      if(searchOption.dateValue){
        start = tools.GMTToStr(searchOption.dateValue[0],2)
        end = tools.GMTToStr(searchOption.dateValue[1],2)
      }
      let param={
        GAID:gid.value,
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
        Type:searchOption.status,
        Name:searchOption.key,
        Start:start,
        End:end
      }
      GroupAnnounceGetSendResult(param).then(res=>{
        console.log(res);
        sourceData.data = res
        pageObj.TotalRow=res.TotalCount
        tableData.value=res.List
        res.List.forEach(item => {
          item.StatusStr=changeStatusToStr(item.Status) 
        });
      })
    }

    //重置
    function resetAction() {
      searchOption.key = '';
      searchOption.status =-1;
      searchOption.dateValue = '';
      searchAction();
    }

    //转状态
    function changeStatusToStr(num) {
      if(num==0){
        return "待发布"
      } else if(num==10){
        return "成功"
      } else if(num==11){
        return "失败"
      } else if(num==3){
        return "已取消"
      }
    }

    //批量重发
    function lotsRetryAction() {
      let param = {
        GAID:gid.value,
        GroupID:0
      }
      GroupAnnounceRetry(param).then(res=>{
        ElMessage.success("批量重发成功")
        searchAction()
      })
    }

    //重发
    function resendAction(item) {
      let param = {
        GAID:gid.value,
        GroupID:item.GroupID
      }
      GroupAnnounceRetry(param).then(res=>{
        ElMessage.success("重发成功")
        searchAction()
      })
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }
    return {
      dialogVisible,
      initCop,
      tableData,
      searchAction,
      gid,
      searchOption,
      statusList,
      pageObj,
      changeSize,
      changePage,
      changeStatusToStr,
      resetAction,
      lotsRetryAction,
      resendAction,
      missionName,
      sourceData
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.group-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    flex-wrap: wrap;
}

:deep().el-table--border::after, .el-table--group::after{
  width: 0;
}
</style>