<template>
  <div class="container">
    <el-dialog title="群公告" width="500px" v-model="dialogVisible">
      <div class="wrap">
        <div style="white-space: pre-wrap">
          {{ publishContent.AnnounceContent }}
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button> -->
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import tools from "@/assets/js/tools";
export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const publishContent = ref("");

    function initCop(item) {
      dialogVisible.value = true;
      publishContent.value = item;
    }

    return {
      dialogVisible,
      initCop,
      publishContent,
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

:deep().el-button--default {
  background: #ccc !important;
  border-color: #ccc !important;
  color: #ffffff !important;
  cursor: auto;
}
</style>