<template>
  <div class="table-foot">
    <div>
      <!-- 这里暂时空着 -->
    </div>
    <div class="table-foot-right">
      <el-pagination
        layout="total, prev, pager, next, sizes"
        background
        @current-change="currentchange"
        :page-sizes="pagesizes"
        :page-size="state.PageSize"
        @size-change="sizeChange"
        :current-page="state.PageIndex"
        :pager-count="5"
        :total="state.TotalRow"
        ref="Pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from "vue";
export default {
  name: "TableFoot",
  props: {
    pagination: {
      type: Object,
      default: {},
    },
    pagesizes: {
      type: Array,
      default: [5, 10, 20, 50, 100],
    },
  },
  setup(props, context) {
    const Pagination = ref(null);
    const pagesizes = props.pagesizes;

    let state = reactive({
      TotalRow: props.pagination.TotalRow || 150,
      PageSize: props.pagination.PageSize || 10,
      PageIndex: props.pagination.PageIndex || 1,
    });
    onMounted(() => {});

    watchEffect(() => {
      state = props.pagination;
    });

    //点击搜索按钮的时候 将页码置为1
    const search = () => {
      state.PageIndex = 1;
    };
    const currentchange = (val) => {
      context.emit("changePage", val);
    };
    const sizeChange = (val) => {
      context.emit("changeSize", val);
    };
    return {
      state,
      currentchange,
      sizeChange,
      search,
      Pagination,
      pagesizes,
    };
  },
};
</script>
<style lang='scss' scoped>
:deep().el-pager .active{
  background-color: $color-common !important;
}

:deep().el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon{
  display: inline-block !important;
}
:deep().el-pagination .btn-prev .el-icon, .el-pagination .btn-prev .el-icon{
  display: inline-block !important;
}

:deep().el-pager li:hover{
  color:$color-common !important;
}
</style>