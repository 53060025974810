<template>
  <div>
    <div class="table-head">
      <div class="table-search" >
        <el-space>
          <div >
            <span>{{theme == 0?'群名称':'任务名称'}}:</span>
            <el-input v-model="searchOption.key" placeholder="请输入"></el-input>
          </div>
          <div v-if="theme==1">
            <span>状态：</span>
            <el-select v-model="searchOption.status" placeholder="全部">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <span>{{theme == 0?'最后更新时间':'发布时间'}}:</span>
            <el-date-picker v-model="searchOption.dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-space>
        <div>
            <el-button type="primary" @click="searchAction">搜索</el-button>
            <el-button @click="resetAction">重置</el-button>
          </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div v-if="theme == 0">群公告(共<span>{{pageObj.TotalRow||0}}</span>个)</div>
        <div v-else>群公告日志(共<span>{{pageObj.TotalRow||0}}</span>个)</div>
        <el-button type="primary" @click="publishAction">发布</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="GroupName" label="群名称" v-if="theme == 0"></el-table-column>
        <el-table-column prop="Name" label="任务名称" v-if="theme == 1" ></el-table-column>
        <el-table-column prop="AnnounceContent" label="群公告" ref="isOverflow" width="450">
          <template #default="scope">
            <div v-if="scope.row.AnnounceContent.length<=30">{{scope.row.AnnounceContent.replace(/\n/g,'')}}</div> 
            <div v-if="scope.row.AnnounceContent.length>30">
              <span>{{scope.row.AnnounceContent.replace(/\n/g,'').substr(0, 30) + "..."}}</span>
              <span><el-button type="text" @click="showAll(scope.row)">[显示全部]</el-button></span> 
            </div>
          </template>
        </el-table-column>
       
        <el-table-column prop="GroupCount" label="关联群数" v-if="theme == 1">
          <template #default="scope">
            <div class="hasUse" @click="groupCount(scope.row)">{{scope.row.GroupCount||0}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ReleaseTime" label="发送时间" v-if="theme == 1" ></el-table-column>
        <el-table-column prop="StatusStr" label="状态" v-if="theme == 1" ></el-table-column>
        <el-table-column label="进度" v-if="theme == 1"  width="120">
        <template #default="scope">
            <div class="fail-wrap">
              <p>{{ scope.row.Process }}%</p>
              <el-slider
                v-model="scope.row.Process"
                :min="0"
                :max="100"
                disabled
                class="slider"
              ></el-slider>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="LastUpdateTime" label="最后更新时间" v-if="theme == 0"></el-table-column>
        <el-table-column prop="ReleaseResultStr" label="发送结果" v-if="theme == 1">
          <template #default="scope">
            <div v-if="scope.row.Status==2">{{scope.row.ReleaseResultStr}}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" v-if="theme == 1">
          <template #default="scope">
            <div v-if="scope.row.Status==0">
              <el-button type="primary" @click="edithAction(scope.row)">编辑</el-button>
              <el-button type="primary" @click="cancelAction(scope.row)">取消</el-button>
            </div>
            <el-button v-if="scope.row.Status==2 || scope.row.Status==3" type="primary" @click="copyAction(scope.row)">复制</el-button>
            <el-button type="primary" v-if="scope.row.Status==1"  @click="stopAction(scope.row)">终止</el-button>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj" 
        @changePage="changePage" 
        @changeSize="changeSize" />
    </div>
  </div>
  <PublishAll ref="publishAllRef"/>
  <PublishGroup ref="publishGroupRef" />
</template>
<script>
import { reactive,ref,onMounted,computed,watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "../../../components/TableFoot";
import {useStore} from "vuex";
import {GetGroupAnnounceList,GetGroupAnnounceLog,GroupAnnounceCancel,GroupAnnounceStop} from '@/helper/group'
import { ElMessageBox, ElMessage } from "element-plus";
import  tools  from '@/assets/js/tools'
import PublishAll from "@/components/PublishAll"
import PublishGroup from "@/components/PublishGroup"

export default {
  name: "PublishList",
  components: {
      TableFoot,
      PublishAll,
      PublishGroup
    },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const store = useStore();
    const theme = computed(() => store.getters.publishInfo.theme)
    const publishAllRef=ref(null)
    const publishGroupRef=ref(null)
    onMounted(()=>{
      searchAction()
    })

    watch(() => store.getters.publishInfo.theme, (curVal, prevVal) => {     
      pageObj.PageIndex = 1; 
      searchAction()
    })
    
    function publishAction(){
      router.push("/grouppublish/publish")
    }
    
    function edithAction(item){
      router.push({ name: "Publish", query: { SID: item.GAID} });
    }
    
    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: -1},{ label: "已发布", value: 2 },{ label: "发布中", value: 1 },
      { label: "待发布", value: 0 },{label:"已取消",value:3},{label:"已终止",value:4}]
    const searchOption = reactive({
        key:'',
        status:-1,
        dateValue:""
    })

    //执行搜索
    function searchAction(){
      let start = "";
      let end="";
      if(searchOption.dateValue){
        start = tools.GMTToStr(searchOption.dateValue[0])
        end = tools.GMTToStr(searchOption.dateValue[1])
      }
      let param = {
          PageNum:pageObj.PageIndex,
          PageSize:pageObj.PageSize,
          Name:searchOption.key,
          StartTime:start,
          EndTime:end,
        }
        if(store.getters.publishInfo.theme==1){
          param.Type=searchOption.status
          GetGroupAnnounceLog(param).then(res=>{
            pageObj.TotalRow=res.TotalCount;
            tableData.value = res.List;
            res.List.forEach(item=>{
              item.StatusStr =changeStatusToStr(item.Status)
              item.ReleaseResultStr=item.ReleaseResult==0?'部分成功':'全部成功'
            })
          })
        } else {
          GetGroupAnnounceList(param).then(res=>{
            pageObj.TotalRow=res.TotalCount;
            tableData.value = res.List;
            // res.List.forEach(item=>{
            //   item.StatusStr =changeStatusToStr(item.Status)
            // })
          })
        }

    }

    //转状态
    function changeStatusToStr(num) {
      if(num==0){
        return "待发布"
      } else if(num==1){
        return "发布中"
      } else if(num==2){
        return "已发布"
      } else if(num==3){
        return "已取消"
      } else if(num==4){
        return "已终止"
      }
    }

    //重置条件
    function resetAction(){
        searchOption.key = '';
        searchOption.status =-1;
        searchOption.dateValue = '';
        searchAction();
    }

  /****************点击事件*******************/
  //终止
  function stopAction(item){
    ElMessageBox.confirm(`确定要取消发布吗？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GroupAnnounceStop({ GAID: item.GAID }).then((res) => {
            ElMessage({
              type: "success",
              message: "取消成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
  }
  //复制
  function copyAction(item) {
    router.push({ name: "Publish", query: { SID: item.GAID,isCopy:true} });
  }
    //取消发布
    function cancelAction(item){
      console.log(item);
       ElMessageBox.confirm("确定要取消发布吗？?", '操作提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          GroupAnnounceCancel({GAID:item.GAID}).then(res=>{
            searchAction()
          })
        }).catch(()=>{
          ElMessage('操作已取消')
        })
    }
    //显示全部
    function showAll(item){
      publishAllRef.value.initCop(item)
    }

    //关联群数
    function groupCount(item){
      publishGroupRef.value.initCop(item)
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return {
      tableData,
      searchOption,
      statusList,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      publishAction,
      theme,
      changeStatusToStr,
      cancelAction,
      showAll,
      publishAllRef,
      groupCount,
      publishGroupRef,
      edithAction,
      copyAction,
      stopAction
    };
  },
};
</script>
<style lang="scss" scoped>
.list-title  div span{
  color:$color-common;
}
:deep().el-table .cell{
  white-space: pre-wrap !important;
}
.hasUse{
    text-decoration: underline;
    cursor: pointer;
    color: $color-common;
}
.showInline {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.slider {
  width: 60px;

  :deep() .el-slider__bar {
    background-color: $color-common;
  }

  :deep() .el-slider__button {
    width: 10px;
    height: 10px;
    // border:none;
  }
}
.fail-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: 34px;
  }
  span {
    color: $color-common;
  }
}
</style>